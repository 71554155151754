<template>
    <div v-if="wallet">

        <a v-if="wallet.aboutUrl" :href="wallet.aboutUrl" class="btn-light btn-m" target="_blank">Don't have {{ wallet.name
        }}?</a>

        <div class="wallet-info">
            <!-- Сканируйте код ниже -->
            Scan the code below
        </div>

        <div class="wallet-qr">
            <ui-img :src="qrCode" radius="8px" loadingColor="var(--secondary-bg-color)" />
        </div>

        <a type="buttom" :href="linkWallet" class="btn btn-m" target="_blank" :disabled="loading">Open {{ wallet.name }}</a>

        <button @click="cancel" class="btn-light btn-m">Cancel</button>

    </div>

    <div v-show="!wallet">

        <div class="wallet-info">
            <!-- Выберите кошелёк для подключения -->
            Select a wallet to connect
        </div>

        <div v-for="item in wallets" @click="() => open(item)" class="wallet-item">
            <div class="wallet-icon">
                <ui-img :src="item.imageUrl" radius="8px" loadingColor="var(--secondary-bg-color)" />
            </div>
            <div class="wallet-title">{{ item.name }}</div>
            <div class="wallet-icon-right">
                <RightIcon />
            </div>
        </div>
    </div>
</template>

<script>
import { api } from '@/Run.js'
import { mapMutations } from 'vuex'

import './style.css'

import RightIcon from '@/assets/svg/icon/Right.vue'

export default {
    name: 'tonConnect',
    data() {
        return {
            loading: false,
            wallet: null,
            qrCode: null,
            linkWallet: null,
            checkLogin: null
        }
    },
    props: {
        wallets: Array
    },
    components: {
        RightIcon
    },
    methods: {
        ...mapMutations({
            setRoot: 'main/setRoot'
        }),
        check() {

            if (this.wallet) {

                this.checkLogin = new AbortController()

                api('post', '/tonconnect/check', {
                    wallet: this.wallet.name
                }, {
                    signal: this.checkLogin.signal
                }).then((res) => {

                    if (res.root) {

                        this.setRoot(res.root)

                    }

                })

            }
        },
        open(item) {

            this.wallet = item
            this.qrCode = null
            this.loading = true

            api('post', '/tonconnect/create', {
                wallet: this.wallet.name
            }).then((res) => {

                if (res.qrCode && res.linkWallet) {

                    this.qrCode = res.qrCode
                    this.linkWallet = res.linkWallet
                    this.check()

                } else {

                    this.wallet = null

                }

                this.loading = false

            })
        },
        cancel() {
            this.wallet = null
            this.qrCode = null
            this.checkLogin && this.checkLogin.abort()
        }
    },
    beforeUnmount() {
        this.cancel()
    }
}
</script>