<template>
  <div class="loading-box">
    <div class="loading-box-center">
      <span class="loading-spinner" :style="{
        borderLeftColor: color,
        borderRightColor: color,
        borderBottomColor: color
      }"></span>
    </div>
  </div>
</template>
  
<script>
import './style.css'

export default {
  name: 'ui-loading',
  props: {
    color: String
  }
}
</script>