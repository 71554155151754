<template>
    <div class="modal" @click="close">
        <div class="modal-box">
            <div class="modal-box-top"></div>
            <div class="modal-box-bottom" @click.stop>

                <div class="modal-box-header">
                    <div class="modal-box-left">
                        <button v-if="left" class="modal-btn" @click="left">
                            <LeftIcon class="icon" />
                        </button>
                    </div>
                    <div class="modal-box-title">
                        <div v-if="title">{{ title }}</div>
                    </div>
                    <div class="modal-box-right">
                        <button v-if="close" class="modal-btn" @click="close">
                            <CloseIcon class="icon" />
                        </button>
                    </div>
                </div>
                <slot />

            </div>
        </div>
    </div>
</template>
    
<script>
import './style.css'

import LeftIcon from '@/assets/svg/icon/Left.vue'
import CloseIcon from '@/assets/svg/icon/Close.vue'

export default {
    name: 'ui-modal',
    data() {
        return {
            height: null
        }
    },
    props: {
        title: String,
        left: Function,
        close: Function
    },
    components: {
        LeftIcon,
        CloseIcon
    },
    beforeMount() {
        document.body.style.overflowY = 'hidden'

    },
    beforeUnmount() {
        document.body.style.overflowY = 'auto'
    }
}
</script>