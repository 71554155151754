<template>
    <div v-if="main.root" class="box">

        <router-view />

        <!-- <div class="footer">
            <Menu />
        </div> -->

    </div>

    <div v-else class="box">
        <Welcome />
    </div>
</template>


<script>
import './style.css'

import { api } from '@/Run.js'
import { mapState, mapMutations } from 'vuex'

import Menu from '@/components/menu'
import Welcome from '@/components/welcome'

export default {
    name: 'Game',
    data() {
        return {}
    },
    components: {
        Menu,
        Welcome
    },
    computed: {
        ...mapState({
            main: 'main',
        })
    },
    methods: {
        ...mapMutations({
            setRoot: 'main/setRoot'
        })
    },
    mounted() {
        const telegramApp = window.Telegram.WebApp
        const telegram = !telegramApp || !telegramApp.platform || telegramApp.platform === 'unknown' ? true : false

        const data = telegram ? {} : {
            telegramData: telegramApp.initDataUnsafe
        }

        api('post', '/main', data).then((res) => {

            if (res.root) {

                this.setRoot(res.root)

            }

            if (telegram) {

                telegramApp.ready()

            }

        })
    },
}

</script>