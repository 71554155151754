<template>
    <TonConnect :wallets="wallets" />
</template>

<script>

import TonConnect from '@/components/tonConnect'

export default {
    name: 'WelcomeAuthorization',
    data() {
        return {}
    },
    props: {
        wallets: Array
    },
    components: {
        TonConnect
    }
}
</script>