<template>
    <div class="menu">
        <RouterLink to="/game" class="item" :class="this.$route.name === 'game.profile' && 'active'">
            <UesrIcon class="icon" />
            Profile
        </RouterLink>
        <RouterLink to="/game/shop" class="item" :class="this.$route.name === 'game.shop' && 'active'">
            <ShopIcon class="icon" />
            Shop
        </RouterLink>
        <RouterLink to="/game/club" class="item" :class="this.$route.name === 'game.club' && 'active'">
            <ClubIcon class="icon" />
            Club
        </RouterLink>
        <RouterLink to="/game/settings" class="item" :class="this.$route.name === 'game.settings' && 'active'">
            <GearIcon class="icon" />
            Settings
        </RouterLink>
    </div>
</template>

<script>
import './style.css'

import UesrIcon from '@/assets/svg/icon/User.vue'
import ShopIcon from '@/assets/svg/icon/Shop.vue'
import ClubIcon from '@/assets/svg/icon/Club.vue'
import GearIcon from '@/assets/svg/icon/Gear.vue'

export default {
    name: 'Menu',
    components: {
        UesrIcon,
        ShopIcon,
        ClubIcon,
        GearIcon
    }
}
</script>