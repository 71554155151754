import axios from 'axios'
import store from '@/store'

const baseUrl = '//192.168.1.118:5003'

const sleep = (ms) => {

    return new Promise(resolve => setTimeout(resolve, ms))

}

const create = axios.create({

    baseURL: baseUrl,
    timeout: 1800000,
    validateStatus: status => {

        return status >= 200 && status < 300

    }

})

const api = async (method, url, data = {}, config = {}, count = 1) => {

    return await create[method](baseUrl + url, data, {
        headers: {
            'Content-Type': 'application/json'
        },
        withCredentials: true,
        ...config
    }).then(function (response) {

        return response.data ? response.data : {}

    }).catch(async error => {

        if (error.response && error.response.status === 401) {

            return await api('post', '/reToken', {}, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('token')
                }
            }, count).then(async (res) => {

                if (res.reToken && count <= 1) {

                    localStorage.setItem('token', res.reToken)
                    return await api(method, url, data, config, count + 1)

                }

                localStorage.removeItem('token')
                store.commit('main/setRoot', false)
                return {}

            })

        } else if (error.response && error.response.status === 403) {

            store.commit('main/setRoot', false)
            return {}

        } else {

            if (config.signal && config.signal.aborted) {

                return {}

            } else {
                return await sleep(count * 2500).then(() => {

                    if (count <= 3) {

                        return api(method, url, data, config, count + 1)

                    } else {

                        store.commit('main/setError', 'api')
                        return {}

                    }

                })
            }

        }

    })

}

export { api }