<template>
    <div v-if="loading" class="img-loading-square" :style="{ borderRadius: radius }">
        <div class="img-loading-square-center">
            <ui-loading :color="loadingColor" />
        </div>
    </div>
    <div v-else class="img" :style="{ backgroundImage: 'url(' + src + ')', borderRadius: radius }" />
</template>
    
<script>
import './style.css'

export default {
    name: 'ui-img',
    data() {
        return {
            loading: true
        }
    },
    props: {
        src: String,
        radius: String,
        loadingColor: String
    },
    methods: {
        start() {
            if (this.src) {
                this.img = this.src
                const loadImage = new Image()
                loadImage.src = this.src
                loadImage.onload = () => this.loading = false
            }
        }
    },
    beforeMount() {
        this.start()
    },
    beforeUpdate() {
        this.start()
    }
}
</script>