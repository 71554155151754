import { createRouter, createWebHistory } from 'vue-router'

import Game from '@/views/game'
import Profile from '@/views/game/profile'
import Shop from '@/views/game/shop'
import Club from '@/views/game/club'
import Settings from '@/views/game/settings'

const routes = [
  {
    path: '/',
    name: 'home'
  }, {
    path: '/game',
    name: 'game',
    component: Game,
    children: [
      {
        name: 'game.profile',
        path: '/game',
        component: Profile,
      },{
        name: 'game.shop',
        path: '/game/shop',
        component: Shop,
      },{
        name: 'game.club',
        path: '/game/club',
        component: Club,
      },{
        name: 'game.settings',
        path: '/game/settings',
        component: Settings,
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
