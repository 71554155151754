<template>
    <button class="btn">
        <div v-if="loading" class="loading-btn-box">
            <div class="loading-btn-box-center">
                <span class="loading-btn-spinner" :style="{
                    borderLeftColor: loadingColor,
                    borderRightColor: loadingColor,
                    borderBottomColor: loadingColor
                }"></span>
            </div>
        </div>
        <slot v-else  />
    </button>
</template>
    
<script>
import './style.css'

export default {
    name: 'ui-button',
    data() {
        return {
            loading: true
        }
    },
    props: {
        loadingColor: String,
        loading: Boolean
    }
}
</script>