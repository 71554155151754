<template>
    <div class="pt">

        <div v-if="listAddress">
            <ui-input placeholder="Address" :class="addressError ? 'is-invalid' : ''" v-model="address" />

            <ui-button @click="next" :loading="loadingNext" :disabled="loadingNext" loadingColor="var(--button-text-color)"
                class="btn btn-m">Next</ui-button>
        </div>
        <div v-else>
            <ui-input placeholder="0" v-model="amout" />

            <ui-button @click="send" class="btn btn-m">Send</ui-button>
        </div>
    </div>
</template>

<script>
import { api } from '@/Run.js'

export default {
    data() {
        return {
            address: null,
            loadingNext: false,
            addressError: false,
            listAddress: true,
            amout: null
        }
    },
    methods: {
        next() {

            this.loadingNext = true

            api('post', '/balance/checkAddress', {
                address: this.address
            }).then((res) => {

                if (res.error) {
                    this.addressError = res.error
                } else {
                    this.listAddress = this.listAddress ? false : true
                }
                this.loadingNext = false
            })

        },
        send() {
            // this.listAddress = this.listAddress ? false : true

            api('post', '/balance/send', {
                address: this.address,
                amout: this.amout
            }).then((res) => {



            })
        }
    }
}
</script>