import Modal from "./modal"
import Img from "./img"
import Button from "./button"
import Input from "./input"

import Loading from "./loading"

export default [
    Modal,
    Img,
    Button,
    Input,
    Loading
]