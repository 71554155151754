<template>
    <div class="balance">
        <div class="balance-main">
            0 <span>VSC</span>
        </div>
        <div class="balance-btn-list">
            <div>
                <button @click="send" class="balance-btn">
                    <div class="icon">
                        <UpIcon />
                    </div>
                    Send
                </button>
            </div>
            <div>
                <button class="balance-btn">
                    <div class="icon">
                        <DownIcon />
                    </div>
                    Receive
                </button>
            </div>
            <!-- <div>
                <button @click="buy" class="balance-btn">
                    <div class="icon">
                        <PlusIcon />
                    </div>
                    Buy
                </button>
            </div> -->
            <div>
                <button class="balance-btn">
                    <div class="icon">
                        <UpDownIcon />
                    </div>
                    Swap
                </button>
            </div>
        </div>
    </div>

    <ui-modal v-if="modalSend" :title="'Send'" :close="send">
        <Send />
    </ui-modal>

    <ui-modal v-if="modalBuy" :title="'Buy'" :close="buy">
        test <br />
        test <br />
        test
    </ui-modal>
</template>

<script>
import './style.css'

import PlusIcon from '@/assets/svg/icon/Plus.vue'
import UpIcon from '@/assets/svg/icon/Up.vue'
import DownIcon from '@/assets/svg/icon/Down.vue'
import UpDownIcon from '@/assets/svg/icon/UpDown.vue'

import Send from './send/index.vue'

export default {
    name: 'ProfileBalance',
    data() {
        return {
            modalBuy: false,
            modalSend: false
        }
    },
    components: {
        PlusIcon,
        UpIcon,
        DownIcon,
        UpDownIcon,
        Send
    },
    methods: {
        buy() {
            this.modalBuy = this.modalBuy ? false : true
        },
        send() {
            this.modalSend = this.modalSend ? false : true
        }
    }
}

</script>