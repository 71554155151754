export const mainModule = {
    state: {
        loading: true,
        root: false,
        user: {}
    },
    getters: {
    },
    mutations: {
        setLoading(state, data) {
            state.loading = data
        },
        setRoot(state, data) {
            state.root = data
        },
        setUser(state, data) {
            state.user = data
        }
    },
    actions: {
    },
    modules: {
    },
    namespaced: true
}