<template>
    <div class="page">
        <Balance />

        <!-- <h2>Game rooms</h2>
        <div class="box-list"></div>

        <h2>Battle cards</h2>
        <div class="box-list"></div> -->

    </div>
</template>

<script>
import './style.css'

import Balance from './balance'

export default {
    name: 'Profile',
    data() {
        return {}
    },
    components: {
        Balance
    },
    methods: {}
}

</script>