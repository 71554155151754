<template>
    <div class="welcome">

        <div class="page">
            <h1>
                Welcome to <span>vsCard</span>
            </h1>

            <div class="item">
                <div class="item-icon">
                    <GameIcon />
                </div>
                <div class="item-box">
                    <div class="item-title">Card game</div>
                    <!-- <div class="item-text">Карточная игра - стратегический поединок с помощью карт. Комбинируй и побеждай!</div> -->
                    <div class="item-text">Strategic battle using cards. Combine and conquer!</div>
                </div>
            </div>

            <div class="item">
                <div class="item-icon">
                    <RingIcon />
                </div>
                <div class="item-box">
                    <div class="item-title">Tournaments</div>
                    <!-- <div class="item-text">Турниры по карточным играм — сражения ума и стратегии, где лучшие соревнуются за славу и призы.</div> -->
                    <div class="item-text">Card game tournaments are battles of intellect and strategy, where the best
                        compete for glory and prizes.</div>
                </div>
            </div>

            <ui-button class="btn-b" @click="getModal" :loading="loading" :disabled="false"
                loadingColor="var(--button-text-color)">Authorization</ui-button>

        </div>

    </div>

    <ui-modal v-if="modal" :title="'Authorization'" :close="closeModal">

        <Authorization :wallets="wallets" />

    </ui-modal>
</template>

<script>
import { api } from '@/Run.js'

import './style.css'

import GameIcon from '@/assets/svg/icon/Game.vue'
import RingIcon from '@/assets/svg/icon/Ring.vue'

import Authorization from './Authorization.vue'

export default {
    name: 'Game',
    data() {
        return {
            loading: false,
            modal: false,
            wallets: []
        }
    },
    components: {
        GameIcon,
        RingIcon,
        Authorization
    },
    methods: {
        getModal() {
            this.loading = true

            api('get', '/tonconnect/wallets', {}).then((res) => {

                if (res.wallets) {
                    this.modal = true
                    this.wallets = res.wallets
                }

            })
        },
        closeModal() {
            this.modal = false
            this.loading = false
        }
    }
}

</script>